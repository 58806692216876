<template>
    <div class="material__widget material__widget-questions">
        <h3 class="widget__title v-h2">Вопросы для тестирования</h3>
        <div
            v-if="questions.data && questions.data.length >= 1"
            class="widget-questions__list"
        >
            <div
                v-for="(question, index) in questions.data"
                class="widget-questions__item"
            >
                {{index+1}}.
                {{question.text}}

                <div class="widget-questions__btns">
                    <icon
                        @click.native="editQuestion(question)"
                        class="widget-questions__btn"
                        width="12px"
                        height="12px"
                    >
                        <path d="M0 9.49977V12.0001H2.50035L9.87802 4.62242L7.37767 2.12207L0 9.49977Z" fill="#989CAE"/><path d="M11.805 1.7519L10.2481 0.195027C9.98808 -0.065009 9.56468 -0.065009 9.30464 0.195027L8.08447 1.4152L10.5848 3.91554L11.805 2.69537C12.0651 2.43534 12.0651 2.01193 11.805 1.7519Z" fill="#989CAE"/>
                    </icon>

                    <icon
                        @click.native="removeQuestion(question)"
                        class="widget-questions__btn"
                        width="12px"
                        height="12px"
                    >
                        <g clip-path="url(#clip0)"> <path d="M9.96825 1.2037H7.82477V0.391312C7.82477 0.175219 7.64957 0 7.43346 0H4.56721C4.35112 0 4.1759 0.175195 4.1759 0.391312V1.2037H2.03243C1.81633 1.2037 1.64111 1.3789 1.64111 1.59502V2.7987C1.64111 3.01479 1.81631 3.19001 2.03243 3.19001H9.9682C10.1843 3.19001 10.3595 3.01481 10.3595 2.7987V1.59502C10.3595 1.37888 10.1843 1.2037 9.96825 1.2037ZM7.04212 1.2037H4.95853V0.782602H7.04212V1.2037Z" fill="#989CAE"/> <path d="M2.18457 3.97266L2.50904 11.6254C2.5179 11.8348 2.69033 12.0001 2.89998 12.0001H9.10079C9.31044 12.0001 9.48287 11.8348 9.49173 11.6254L9.81617 3.97266H2.18457ZM4.81749 10.4088C4.81749 10.6249 4.6423 10.8001 4.42618 10.8001C4.21009 10.8001 4.03487 10.6249 4.03487 10.4088V5.56392C4.03487 5.34783 4.21006 5.17261 4.42618 5.17261C4.64227 5.17261 4.81749 5.34781 4.81749 5.56392V10.4088ZM6.39162 10.4088C6.39162 10.6249 6.21643 10.8001 6.00031 10.8001C5.7842 10.8001 5.609 10.6249 5.609 10.4088V5.56392C5.609 5.34783 5.7842 5.17261 6.00031 5.17261C6.21643 5.17261 6.39162 5.34781 6.39162 5.56392V10.4088ZM7.96576 10.4088C7.96576 10.6249 7.79056 10.8001 7.57445 10.8001C7.35833 10.8001 7.18313 10.6249 7.18313 10.4088V5.56392C7.18313 5.34783 7.35833 5.17261 7.57445 5.17261C7.79056 5.17261 7.96576 5.34781 7.96576 5.56392V10.4088Z" fill="#989CAE"/> </g> <defs> <clipPath id="clip0"> <rect width="12" height="12" fill="white"/> </clipPath> </defs>
                    </icon>
                </div>
            </div>
        </div>

        <span
            v-else
            class="v-desc d-block mt-10 mb-10"
        >
            Вопросы не добавлены
        </span>

        <button
            type="button"
            class="widget__btn v-btn v-btn--gold-bordered"
            @click="openModal(null)"
        >
            + Добавить вопрос
        </button>

        <div
            v-if="categoryType === 'document' && questions.data && questions.data.length >= 1"
            class="widget-questions__footer"
        >
            <div class="widget-questions-count">
                <label
                    class="widget-questions-count__label"
                >
                    Количество вопросов для тестирования
                    <sup
                        class="tooltip-trigger tooltip-trigger--info"
                        v-tooltip="{ content: 'Можно указать определённое количество вопросов, которые будут участвовать в тестирование.', offset: 7 }"
                    >
                        i
                    </sup>
                </label>

                <input
                    class="widget-questions-count__field form-control"
                    type="number"
                    placeholder="Кол-во"
                    min="1"
                    :max="questions.data.length"
                    v-model="questionsCount"
                    @input="(event) => this.onChangeCount(event, 'questions')"
                >
            </div>

            <div class="widget-questions-count">
                <label
                    class="widget-questions-count__label"
                >
                    Количество правильных ответов
                    <sup
                        class="tooltip-trigger tooltip-trigger--info"
                        v-tooltip="{ content: 'Можно указать определённое количество ответов, для удачного прохождения тестирования.', offset: 7 }"
                    >
                        i
                    </sup>
                </label>

                <input
                    class="widget-questions-count__field form-control"
                    type="number"
                    placeholder="Кол-во"
                    min="1"
                    :max="questions.data.length"
                    v-model="answersCount"
                    @input="(event) => this.onChangeCount(event, 'answers')"
                >
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session'
    export default {
        name: 'QuestionsList',
        components: {
            Icon : () => import('@/components/icon/Icon')
        },
        emits: ['changeCount'],
        props: {
            categoryType: {
                type: String,
                default: 'material'
            },

            categoryId: {
                type: String,
                default: null,
            },

            categoryTitle: {
                type: String,
                default: null
            },

            count: {
                type: Object,
                default: () => ({
                    answers: 0,
                    questions: 0,
                })
            }
        },
        data () {
            return {
                questions: {},
                questionsCount: this.count.questions,
                answersCount: this.count.answers
            }
        },
        watch: {
            count: {
                handler(newVal, oldVal) {
                    this.questionsCount = newVal.questions;
                    this.answersCount = newVal.answers;
                },
                deep: true
            }
        },
        methods: {
            editQuestion(question) {
                const QuestionChangeModal = () => import(`@/components/test/modals/QuestionChangeModal`);

                this.$modal.show(QuestionChangeModal, {
                    categoryType: this.categoryType,
                    categoryId: this.categoryId,
                    categoryTitle: this.categoryTitle,
                    question: question,
                    onSave: this.getQuestionList
                }, {
                    name: 'question-change-modal',
                    scrollable: true,
                    height: 'auto',
                    adaptive: true,
                    maxWidth: 740,
                    width: '100%',
                    clickToClose: false
                })
            },

            async removeQuestion(question) {
                const QuestionRemoveModal = () => import(`@/components/knowledge-base/detail/question/QuestionRemove`);

                this.$modal.show(QuestionRemoveModal, {
                    question: question
                }, {
                    name: 'question-remove-modal',
                    scrollable: true,
                    height: 'auto',
                    adaptive: true,
                    maxWidth: 460,
                    width: '100%',
                    clickToClose: false
                }, {
                    'before-close': async (data) => {
                        if (data.params) {
                            await this.getQuestionList();
                            this.onChangeQuestionsCount();
                        }
                    }
                })
            },

            async openModal(question) {
                const QuestionChangeModal = () => import(`@/components/test/modals/QuestionChangeModal`);

                this.$modal.show(QuestionChangeModal, {
                    categoryType: this.categoryType,
                    categoryId: this.categoryId,
                    categoryTitle: this.categoryTitle,
                    question: question,
                    onSave: async () => {
                        await this.getQuestionList();
                        this.onChangeQuestionsCount();
                    }
                }, {
                    name: 'question-change-modal',
                    scrollable: true,
                    height: 'auto',
                    adaptive: true,
                    maxWidth: 740,
                    width: '100%',
                    clickToClose: false
                })
            },

            async getQuestionList() {
                const url = {
                    'material': `/api/v1/material/${this.categoryId}/questions/`,
                    'document': `/api/v1/document/${this.categoryId}/questions/`,
                }

                try {
                    const request = await session.get(url[this.categoryType]);

                    this.questions = request.data;
                } catch (error) {
                    console.error('#QuestionsList: ', error);
                }
            },

            onChangeQuestionsCount() {
                this.questionsCount = this.questions.data.length;

                this.$emit('changeCount', {
                    questions: this.questionsCount,
                    answers: this.answersCount,
                });
            },

            onChangeCount(event, type) {
                const { target } = event;
                const { min, max } = target;
                const value = parseInt(target.value);

                if (value < 0) {
                    if (type === 'questions') {
                        this.questionsCount = min
                    } else {
                        this.answersCount = min
                    }
                }

                if (value > max) {
                    if (type === 'questions') {
                        this.questionsCount = max;
                    } else {
                        this.answersCount = max
                    }
                }

                this.$emit('changeCount', {
                    questions: this.questionsCount,
                    answers: this.answersCount,
                });
            }
        },
        async created () {
            await this.getQuestionList();
        },
    }
</script>

<style lang="scss" scoped>
    .widget-questions {
        $bn: &;

        &__list {
            margin: 20px 0;
            padding-left: 0;
        }
        &__item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 20px;
            border-bottom: 1px solid #E6EBF3;
            &:nth-child(n+2) {
                margin-top: 20px;
            }
        }
        &__btn {
            cursor: pointer;
            &:last-child {
                margin-left: 20px;
            }
        }
        &__btns {
            display: flex;
            align-items: center;
            padding: 5px 0;
            margin-left: 20px;
        }

        &__footer {
            margin-top: 30px;
        }

        &-count {
            gap: 10px;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 480px) {
                align-items: center;
                flex-direction: row;
            }

            &__label {
                margin: 0;
                @media screen and (min-width: 1200px) {
                    flex-basis: 330px;
                }
            }

            &__field {
                -moz-appearance:textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                @media screen and (min-width: 480px) {
                    max-width: 90px;
                }
            }

            & + #{$bn}-count {
                margin-top: 10px;
            }
        }
    }
</style>
