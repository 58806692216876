<template>
    <div>
        <loading-spinner v-if="isLoading" />
        <div class="material-header">
            <h2 class="material-header__title">
                Редактирование документа
            </h2>
        </div>
        <ValidationObserver
            tag="form"
            ref="observer"
            method="post"
            enctype="multipart/form-data"
            class="form-propose js-without-double-block"
            @submit.prevent="onSubmit"
        >
            <div class="material-content">
                <!--Вкладка - Материал-->
                <ValidationProvider rules="required|max_length:255" v-slot="provider">
                    <div
                        class="form-group"
                        v-bind:class="{'is-invalid': provider.errors.length > 0}"
                    >
                        <div class="form-material floating open">
                            <label for="id_name">Название документа *</label>
                            <input
                                type="text"
                                name="name"
                                maxlength="255"
                                id="id_name"
                                class="form-control"
                                v-model="form.name"
                            >

                            <div
                                id="id_name-error"
                                class="invalid-feedback animated fadeInDown"
                                v-if="provider.errors.length > 0"
                            >
                                {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <ValidationProvider
                    tag="fragment"
                    vid="rubric"
                    :rules="current_user.is_editor_rubric_docs && !current_user.is_editor_document && !current_user.is_admin ? 'required' : null"
                    v-slot="provider"
                >
                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material floating open">
                            <label for="id_rubrics">
                                Рубрика<span v-if="current_user.is_editor_rubric_docs && !current_user.is_editor_document && !current_user.is_admin"> *</span>
                            </label>

                            <v-select2
                                :reduce="name => name.id"
                                :options="listRubrics" label="name"
                                :clearable="true" :searchable="true"
                                v-model="form.rubric"
                                id="id_rubrics"
                                class="form-material"
                            >
                                <span slot="no-options">Ничего не найдено</span>
                            </v-select2>

                            <div
                                id="id_rubrics-error"
                                class="invalid-feedback animated fadeInDown"
                                v-if="provider.errors.length > 0"
                            >
                                {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="id_materials">
                            Материалы
                        </label>

                        <v-select2
                            v-model="form.material"
                            :reduce="title => title.id"
                            :options="listMaterials" label="title"
                            :clearable="true" :searchable="true"
                            id="id_materials"
                            class="form-material"
                        >
                            <span slot="no-options">Ничего не найдено</span>
                        </v-select2>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="number_document">
                            Номер документа
                        </label>

                        <input
                            id="number_document"
                            class="form-control"
                            type="text"
                            name="number"
                            maxlength="21"
                            v-model="form.number"
                        >
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="approval_date">
                            Дата согласования
                        </label>

                        <VueDatePicker
                            id="approval_date"
                            class="form-control"
                            color="#8e8e8e"
                            placeholder="Выберите дату"
                            :validate="true"
                            :noHeader="true"
                            :noCalendarIcon="true"
                            :locale="{lang: 'ru'}"
                            :format="'DD.MM.YYYY'"
                            :clearable="true"
                            v-model="form.date_approval"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="id_type">
                            Тип документа
                        </label>

                        <v-select2
                            class="form-material"
                            id="id_type"
                            v-model="form.type"
                            :reduce="label => label.value"
                            :options="typeOption"
                            :clearable="false"
                            :searchable="false"
                        ></v-select2>
                    </div>
                </div>

                <ValidationProvider :rules="form.type === 1 ? 'required|max_length:255' : null" v-slot="provider"  vid="url">
                    <div class="form-group" v-show="form.type === 1"
                         v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material floating open">
                            <label for="id_url">
                                Внешняя ссылка на документ
                            </label>
                            <input v-model="form.url" type="text" name="url" maxlength="200" id="id_url"
                                   class="form-control">
                            <div id="id_url-error" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <ValidationProvider :rules="form.type === 2 && form.file && form.file.length === 0 ? 'required' : null" v-slot="provider">
                    <div class="form-group" v-show="form.type === 2"
                         v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material floating open">
                            <label for="id_file">Файл</label>
                            <span v-show="!new_file">{{file_name}} <br> </span>
                            <input v-on:change="onFileChange" type="file" name="file" id="id_file" ref="docFile">
                            <div id="id_file-error" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <label
                    for="update-doc-testing"
                    title="Под участием подразумевается ответы на вопросы."
                    class="form-group css-control"
                >
                    <input
                        type="checkbox"
                        name="selected"
                        id="update-doc-testing"
                        class="css-control-input"
                        v-model="form.testing"
                    >
                    <span class="css-control-indicator"></span>
                    Участвует в тестировании
                </label>

                <div
                    v-if="form.testing"
                    class="doc-test"
                >
                    <questions-list
                        class="document-questions doc-test__box"
                        :category-type="`document`"
                        :category-id="document_id"
                        :category-title="form.name"
                        :count="{
                            questions: this.questionsCount,
                            answers: this.answersCount
                        }"
                        @changeCount="onQuestionsChangeCount"
                    />

                    <div class="doc-test__box">
                        <h2 class="doc-test__title v-h2">
                            Назначение и статусы
                        </h2>

                        <div class="form-material open col-12 mb-15">
                            <div class="material__access-txt">
                                <span v-text="infoBindingText ? `${infoBindingText} ` : 'Не назначен никому '"></span>
                                <sup
                                    class="tooltip-trigger tooltip-trigger--info"
                                    v-tooltip="{ content: 'Можно назначить пользователей для проверки знаний о документе.', offset: 7 }"
                                >
                                    i
                                </sup>
                            </div>

                            <a
                                href="javascript:void(0)"
                                class="material__access"
                                @click="changeBinding"
                            >
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M13.724 1.274a4.35 4.35 0 00-6.966 5.022L.006 13.05l-.004 1.947L3.778 15v-1.836h1.836v-1.837h1.837V9.491L8.7 8.24a4.351 4.351 0 005.023-6.966zm-1.383 3.243a1.315 1.315 0 11-1.86-1.86 1.315 1.315 0 011.86 1.86z"></path>
                                </svg>

                                Назначить пользователям
                            </a>
                        </div>

                        <div
                            v-show="form.testing"
                            class="form-material col-12"
                        >
                            <span class="material__access-txt">
                                Сбросить статус назначенным пользователям
                            </span>

                            <a
                                href="javascript:void(0)"
                                class="material__access"
                                @click="onResetStatus"
                            >
                                Сбросить статус
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row ml-0 mr-0">
                    <div class="form-material open col-12 col-md-4">
                        <span v-show="!infoPermissionText">
                            Документ доступен всем
                        </span>
                        <span v-show="infoPermissionText">
                            <span class="material__access-txt">{{ infoPermissionText }}</span>
                        </span>
                        <a
                            href="javascript:void(0)"
                            class="material__access"
                            @click="changePermission"
                        >
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M13.724 1.274a4.35 4.35 0 00-6.966 5.022L.006 13.05l-.004 1.947L3.778 15v-1.836h1.836v-1.837h1.837V9.491L8.7 8.24a4.351 4.351 0 005.023-6.966zm-1.383 3.243a1.315 1.315 0 11-1.86-1.86 1.315 1.315 0 011.86 1.86z"></path>
                            </svg>

                            Настроить доступ
                        </a>
                    </div>
                </div>

                <div class="material-footer">
                    <router-link
                        tag="div"
                        :to="`/documents`"
                        class="material-footer__reset">
                        Отмена
                    </router-link>

                    <button class="material-footer__add" type="submit">
                        Сохранить
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    import session from '@/api/session';
    import axios from 'axios';
    import { mapState } from "vuex";
    import { eventBus } from '@/main'
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import QuestionsList from '@/components/knowledge_base/widgets/QuestionsList';

    export default {
        name: "UpdateDocument",
        components: {
            LoadingSpinner,
            QuestionsList
        },
        props: {
            document_id: {
                type: String
            }
        },
        data() {
            return {
                infoPermissionText: '',
                infoBindingText: '',
                typeOption: [
                    {
                        value: 1,
                        label: 'Внешняя ссылка'
                    },
                    {
                        value: 2,
                        label: 'Выбрать документ'
                    }
                ],
                listRubrics: [],
                listMaterials: [],
                file_name: '',
                new_file: false,
                form: {
                    name: '',
                    rubric: null,
                    material: '',
                    type: 1,
                    url: '',
                    file: null,
                },
                isLoading: false,
                questionsCount: 0,
                answersCount: 0,
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user',
            ])
        },
       async created() {
            await this.accessesDocument();
            await session
                .get('/api/v1/material/all_list/')
                .then(response => {
                    this.listMaterials = response.data;
                })
                .catch((error) => {
                    console.error(error)
                });
            await session
                .get('/api/v1/document/rubrics/')
                .then(response => {
                    this.listRubrics = response.data;
                })
                .catch((error) => {
                    console.error(error)
                });
            await session
                .get(`/api/v1/document/${this.document_id}/`)
                .then(response => {
                    const data = response.data;

                    this.form = data;
                    this.file_name = data.file_name;
                    this.document = Object.assign({}, data);

                    this.infoPermissionText = data['permission_text'];
                    this.infoBindingText = data['attach_display_text'] || this.infoBindingText;
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.$router.push('/404');
                    }
                });

            if (this.form.testing) {
                let isSettings = false;

                await session
                    .get(`api/v1/document-testing-settings/${this.document_id}/`)
                    .then(response => {
                        const data = response.data;
                        this.questionsCount = parseInt(data.batch_size);
                        this.answersCount = parseInt(data.count_for_passed);

                        isSettings = true;
                    })
                    .catch((error) => {
                        console.error(error);
                    })

                if (!isSettings) {
                    const request = await session.get(`/api/v1/document/${this.document_id}/questions/`)

                    this.questionsCount = request.data.count;
                    this.answersCount = request.data.count;
                }
            }
        },
        methods: {
            changePermission() {
                let checkbox = this.form.rubric? 'Как у родительской рубрики' : 'Для всей компании';
                const ModalPermissionUpdate = () => import(`../../permission/ModalPermissionUpdate`);
                this.$modal.show(ModalPermissionUpdate, {
                    title: 'Настройка доступа к документу',
                    text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному документу',
                    checkbox: checkbox,
                    rubricID: this.form.rubric,
                    isMainRubric: this.document.rubric ? this.form.rubric === this.document.rubric.id : true,
                    permissionSettings: true,
                    permissionType: 'document',
                    permissionTypeParent: 'rubric_document',
                    permissionObjectID: +this.document_id
                }, {
                    name: 'ModalPermissionUpdate',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': this.updateTextPermission
                })
            },

            changeBinding() {
                let checkbox = this.form.rubric? 'Как у родительской рубрики' : 'Для всей компании';
                const ModalBindingUpdate = () => import(`../../permission/ModalBindingUpdate`);
                this.$modal.show(ModalBindingUpdate, {
                    title: 'Настройка привязки к документу',
                    text: 'Выберите отделы, должности или сотрудников, которых необходимо привязать к данному документу',
                    checkbox: checkbox,
                    rubricID: this.form.rubric,
                    isMainRubric: this.document.rubric ? this.form.rubric === this.document.rubric.id : true,
                    permissionSettings: true,
                    permissionType: 'document',
                    permissionTypeParent: 'rubric_document',
                    permissionObjectID: +this.document_id
                }, {
                    name: 'ModalBindingUpdate',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': this.updateTextBinding
                })
            },

            onResetStatus() {
                const ModalResetStatusUser = () => import(`../../permission/ModalResetStatusUser`);
                this.$modal.show(ModalResetStatusUser, {
                    title: 'Сбросить статус назначенным пользователям.',
                    text: 'Выберите из списка пользователей, которым необходимо сбросить статус на "Не изучен".',
                    rubricID: this.form.rubric,
                    isMainRubric: this.document.rubric ? this.form.rubric === this.document.rubric.id : true,
                    permissionSettings: true,
                    permissionType: 'document',
                    permissionTypeParent: 'rubric_document',
                    permissionObjectID: +this.document_id
                }, {
                    name: 'ModalResetStatusUser',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': this.updateStatusUser
                })
            },

            updateTextPermission(data) {
                if (data.params) {
                    data = data.params.data;
                    this.infoPermissionText = data.info_text;
                }
            },
            updateTextBinding(data) {
                const params = data.params;

                if (params) {
                    this.infoBindingText = params.data.text;
                }
            },
            async updateStatusUser(data) {
                const usersId = data.params;
                const url = `/api/v1/document/${this.document_id}/set_unstudied/`;

                this.$Progress.start();

                await session
                    .post(url, { 'users_ids': usersId })
                    .then(async response => {
                        this.$Progress.finish();
                    })
                    .catch((error) => {
                        this.$Progress.finish();

                        if (error.response) {
                            this.$refs.observer.setErrors(error.response.data);
                        }
                    })
            },
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.form.file = files[0];
                this.file_name = this.form.file.name;
                this.new_file = true;
            },

            async onSubmit(e) {
                e.preventDefault();
                let isValidModal = await this.$refs.observer.validate();
                if (isValidModal) {
                    let progress_bar = this.$Progress;
                    let url = `/api/v1/document/${this.document_id}/`;
                    let config = {
                        onUploadProgress(progressEvent) {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) /
                                progressEvent.total);
                            progress_bar.set(percentCompleted);
                            return percentCompleted;
                        },
                    };
                    this.$Progress.start();
                    let data = this.form;

                    if (this.form.file && typeof (this.form.file) !== 'string') {
                        config['headers'] = {
                            'Content-Type': 'multipart/form-data'
                        };
                        // Добавляем данные из объекта в formData для отправки на сервер
                        let formData = new FormData();
                        this.form.rubric = this.form.rubric ? this.form.rubric : '';
                        this.form.material = this.form.material ? this.form.material : '';
                        this.form.url = this.form.url ? this.form.url : '';
                        for (let [key, value] of Object.entries(this.form)) {
                            if (key === 'file') {
                            // файл заливается на фронте, а не на бэке, нет необходимости его отправлять
                                continue
                            }
                            else if (key === 'permissionData') {
                                formData.append('permissionData', JSON.stringify(value));
                            } else {
                                formData.append(key, value);
                            }
                        }
                        let file_name = this.form.file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
                        formData.set('file_name', file_name);
                        formData.set('file_size', this.form.file.size);
                        data = formData;
                    } else {
                        this.form.file = null;
                    }

                    // сохраним "Дата согласования" в нужном формате
                    if (this.form.date_approval) {
                        const format = 'YYYY-MM-DD HH:mm:ss';
                        const date = `${this.$moment(this.form.date_approval).format('YYYY-MM-DD')} ${this.$moment('00:00:00', 'HH:mm:ss').format('HH:mm:ss')}`;

                        this.form.date_approval = this.$moment(date).format(format);
                    } else {
                        this.form.date_approval = null
                    }

                    try {
                        await this.onSaveDocument(url, data, config)
                    } catch (error) {
                        const errorResponse = error.response;


                        this.$Progress.finish()

                        if (errorResponse) {
                            const errorMessage = errorResponse.data[0] || 'Повторите попытку, произошла непредвиденная ошибка.';

                            if (errorResponse.status === 400) {
                                this.$swal({
                                    customClass: {
                                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                                    },
                                    title: errorMessage,
                                    icon: 'warning',
                                    showCancelButton: false,
                                    confirmButtonText: 'Хорошо',
                                })
                            } else {
                                this.$refs.observer.setErrors(error.response.data)
                            }
                        }
                    }

                    this.isLoading = false
                }
            },
            async uploadFileByLink(uploadLink) {
                try {
                    this.isLoading = true
                    await axios.put(uploadLink, this.form.file, {})
                    this.isLoading = false
                } catch (e) {
                    this.isLoading = false
                    console.log('error', e)
                }
            },
            async accessesDocument() {
                await Promise.all([
                    session.get(`/api/v1/permission/retrieve_vue/?permission_type=document&permission_object_id=${this.document_id}`),
                    session.get(`api/v1/permission/retrieve_vue/?permission_type=assign_document&permission_object_id=${this.document_id}`)
                ])
                    .then(([general, assign]) => {
                        const generalData = general.data;
                        const assignData = assign.data;

                        // Общие доступы
                        if (generalData) {
                            this.form.permissionData = generalData;
                            this.form.permissionData.note.parentRight = true;
                            this.infoPermissionText = generalData.info_text;
                            this.permissionDataCache = null;
                        }

                        // Назначения
                        if (assignData) {
                            this.infoBindingText = assignData.info_text;
                        }
                    })
                    .catch((error) => {
                        console.error('error', e.message);
                    });
            },

            onQuestionsChangeCount(data) {
                if (data) {
                    const { questions, answers } = data;

                    this.questionsCount = questions;
                    this.answersCount = answers;
                }
            },

            async onSaveDocument(url, data, config) {
                const response = await session.put(url, data, config);
                const resData = response.data;

                if ('upload_link' in resData) {
                    await this.uploadFileByLink(resData.upload_link)
                }

                if (this.form.testing) {
                    await session
                        .post('/api/v1/document-testing-settings/', {
                            'document_id': this.document_id,
                            'batch_size': this.questionsCount,
                            'count_for_passed': this.answersCount,
                            'minutes': 0,
                            'seconds': 0,
                            'users': []
                        })
                        .catch((error) => {
                            console.error('error', error)

                            if (error.response) {
                                this.$refs.observer.setErrors(error.response.data);
                            }
                        })
                }

                eventBus.$emit('changed-document', response.data);

                this.$Progress.finish();
                this.$router.back();
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .material-footer__reset {
        cursor: pointer;
    }

    .doc-test {
        padding: 20px;
        margin: 16px 0;
        border-radius: 4px;
        border: 2px solid #e6ebf3;
        &__box {
            border-radius: 4px;
            border: 2px solid #e6ebf3;
            padding: 20px;
            & + .doc-test__box {
                margin-top: 30px;
            }
        }

        &__title {
            margin: 0 0 20px;
        }
    }
</style>
